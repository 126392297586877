import React from 'react'
import SiteLayout from '../components/SiteLayout'
import { Row } from 'antd'

const Api_Reference = ({ location }) => (
  <SiteLayout location={location}>
    <Row type="flex" justify="center">
        <iframe src="https://bfm.strands.com/fm-api/api/api.html" />
    </Row>
  </SiteLayout>
)

export default Api_Reference
