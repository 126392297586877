import React, { useEffect } from 'react';
import SiteHeader from "../SiteHeader";
import {Layout} from "antd";

export function Loading() {
  return (
      <>
          <Layout className="strands-layout">
              <SiteHeader location />
          </Layout>
          <section className="strands-layout">
              <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
              </div>
          </section>
      </>
  );
}
