import React from "react"
import { useAuth0 } from '@auth0/auth0-react';

const Profile = () => {
	const { user } = useAuth0();

	return (
		<>
			<h1>Your profile</h1>
			<ul>
				<li><img src={user.picture} /></li>
				<li>Name: {user.nickname}</li>
				<li>E-mail: {user.email}</li>
			</ul>
		</>
	);
};

export default Profile