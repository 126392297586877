import React from 'react';
import { Router } from '@reach/router';
import { useAuth0 } from '@auth0/auth0-react';
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';
import { Error } from '../components/Error/Error';
import Gitbook from "./gitbook";
import Profile from "../components/profile";
import IndexPage from "./index";
import Tutorials from "../components/tutorials";
import Documentation from "../components/documentation";
import UIKIT from "../components/ui_kit";
import Api_Reference from "../components/api_reference";

const PrivatePages = () => {
	const { error } = useAuth0();

	return (
		<>
			{error && <Error message={error.message} />}
			<Router>
				<ProtectedRoute path="/app/profile" component={Profile} />
				<ProtectedRoute path="/app/tutorials" component={Tutorials} />
				<ProtectedRoute path="/app/ui_kit" component={UIKIT} />
				<ProtectedRoute path="/app/docs" component={Documentation} />
				<ProtectedRoute path="/app/api_reference" component={Api_Reference} />
				<ProtectedRoute path="/app/gitbook" component={Gitbook} />
				<IndexPage path="/app" />
			</Router>
		</>
	);
};

export default PrivatePages;