import React, { useEffect }  from 'react'
import { navigate } from 'gatsby';
import {Loading} from "./Loading/Loading";


const Documentation = ({location}) => {
	useEffect(() => {
		navigate('/docs/getting-started/welcome');
	  }, []);
	return <Loading />;
};

export default Documentation
