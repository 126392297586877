import React from 'react'
import SiteLayout from '../components/SiteLayout'
import { Row, Icon } from 'antd'

import UI_Integration from "../images/img_Tutorials_UI_Integration.jpg"
import GA_Integration from "../images/img_Tutorials_GA_Integration.jpg"
import Lorem_Ipsum from "../images/img_Tutorials_Lorem_Ipsum.jpg"


const Tutorials = ({ location }) => (
  <SiteLayout location={location} external>
    <Row type="flex">

      <h2>Frontend Tutorials</h2>

      <div className="flex-container">
        <div className="flex-item tutorialsLink">
          <a href="/tutorials/frontend/ui-integration">
            <h2>UI: Integrating the Budgeting module...</h2><p>The most basic thing you’ll want to do to test out the Strands suite…</p>
          </a>
          <div className="button_module">
            <a className="button_tutorials" href="/tutorials/frontend/ui-integration">Learn More  »</a>
          </div>
        </div>

        <div className="flex-item tutorialsLink">
          <a href="/tutorials/frontend/ga-integration">
            <h2>Connect Strands FM Events with Google Analytics</h2><p>Integrate our events with the most common analytics tools ...</p>
          </a>
          <div className="button_module">
            <a className="button_tutorials" href="/tutorials/frontend/ga-integration">Learn More  »</a>
          </div>
        </div>

      </div>

    </Row>
  </SiteLayout>
)

export default Tutorials
