import React from 'react'
import SiteLayout from '../components/SiteLayout'

const UIKIT = ({location}) => (
  <SiteLayout location={location} external>
    <iframe src="https://storybook.strands.com/develop" />
  </SiteLayout>
)

export default UIKIT
